import { usePages } from '@/contexts/pages';
import { useAppSelector } from '@/redux/hooks';
import { p500, p600 } from '@/styles/typography/fonts';
import { Box, Button, Divider, Typography } from '@mui/material';

type Props = {
	total: number;
	qtd: number;
};

export default function CartBadge({ total, qtd }: Props) {
	const { client } = useAppSelector(state => state.authDelivery);
	const { setOpenCart } = usePages();

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			<Box className="boxBadge">
				<Box>
					<Typography sx={{ fontFamily: p500, color: '#635B5B', fontSize: '0.775rem' }}> Total (sem a entrega)</Typography>
					<Box className="badgeItensPrice">
						<Typography sx={{ fontFamily: p500, color: '#3e3e3e', fontSize: '0.875rem' }}> R$ {total.toFixed(2).replaceAll('.', ',')}</Typography>
						<Typography sx={{ fontFamily: p500, color: '#3e3e3e', fontSize: '0.875rem' }}>
							({qtd} {qtd === 1 ? 'item' : 'itens'})
						</Typography>
					</Box>
				</Box>
				<Button variant="contained" onClick={() => setOpenCart(true)} sx={{ fontFamily: p600, color: '#fff', fontSize: '0.775rem', background: '#' + client?.config_cliente?.cor, '&:hover': { background: '#' + client?.config_cliente?.cor } }}>
					Ver Sacola
				</Button>
			</Box>
		</Box>
	);
}
